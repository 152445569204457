/**
 * Remove default margin.
 */
body {
  margin: 0;
  background-color: #101020;
  color: #f0f0f0;
}

h1 {
  color: #ffffe0;
  text-align: center;
}

h2 {
  color: #ffffff;
  text-align: center;
}

h3 {
  color: #eoffff;
  text-align: center;
}

.CenteredList {
  text-align: center;
}

ul {
  text-align: left;
  display:inline-block;
}

.ComicDate {
  color: #b0b0b0;
  text-align: right;
}
.clear {
  clear: both;
}
.License {
  overflow: hidden;
}
.Icon {
  float: right;
  max-width: 30%;
}


.RightIcon {
  float: right;
  max-width: 20%;
}

.LeftIcon {
  float: left;
  max-width: 20%;
}

.Advertisement {
  color: #a0d0d0;
  text-align: center;
  margin-left: 5%;
  margin-right: 5%;
}

p {
  text-align: center;
  margin-left: 5%;
  margin-right: 5%;
}

.Comic {
  max-width: 100%;
  min-width: 320px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.ComicSelector {
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1536px;
  min-width: 320px;
  text-align: center;
  font-size: 200%;
}

.ComicSelectorButton {
  margin-left: 7%;
  margin-right: 7%;
}

@media only screen and (min-device-width: 320px) and (orientation: landscape) {
  .Icon {
  float: right;
  max-width: 50%;
}
  .Comic, .ComicSelectorOptions {
  min-width: 1024px;
}
}
